import React, { useState, Fragment } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";

const Header = ({ pathname }) => {
  const [isopen, setOpen] = useState(false);
  const toggle = () => {
    // console.log('header');
    setOpen(!isopen);
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const _handleChange = (e) => {
    setError(false);
    setSuccess(false);
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    if (email) {
      setLoading(true);
      const today = new Date();
      const data = { Emails: email, Date: today.toLocaleDateString("en-US") };
      axios
        .post("https://leads.civsav.com/cbgwholesale", {
          data,
        })
        .then(() => {
          setEmail("");
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setMsg("Something went wrong!");
          setError(true);
        });
    } else {
      setMsg("Email Address Required!");
      setError(true);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <meta
          name="google-site-verification"
          content="BJgSbS_wRDGBn94mApzVj1kZMXKb8KvqAt-Ye_jOxfo"
        />
      </Helmet>
      <header
        class={`globalHeader ${isopen ? "-activeNav" : ""}`}
        role="banner"
      >
        <div class="globalHeader-container">
          <Link to="/">
            <div class="logo logo-header">
              <StaticImage
                src="../../assets/images/logo.png"
                className="logo-header"
                imgClassName="logo-header"
                placeholder="blurred"
                width={100}
              />
            </div>
          </Link>
          <svg
            aria-label="Open Navigation"
            class={`hamburger ${isopen ? "-active" : ""}`}
            viewBox="0 0 100 100"
            width="80"
            onClick={toggle}
          >
            <path
              class="hamburger-line hamburger-top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            ></path>
            <path
              class="hamburger-line hamburger-middle"
              d="m 30,50 h 40"
            ></path>
          </svg>
          <nav class="navigation -header" aria-labelledby="header_navLabel">
            <p id="header_navLabel" class="_hidden">
              Header Navigation
            </p>
            <ul class="navigation-list">
              <li class="navigation-listItem">
                <Link
                  to="/cbg-isolate-wholesale"
                  className="navigation-link"
                  data-state={
                    pathname === "/cbg-isolate-wholesale" ? "active" : "disable"
                  }
                >
                  CBG ISOLATE
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link
                  to="/cbg-flower-wholesale"
                  className="navigation-link"
                  data-state={
                    pathname === "/cbg-flower-wholesale" ? "active" : "disable"
                  }
                >
                  CBG FLOWER
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link
                  to="/cbg-oil-wholesale"
                  className="navigation-link"
                  data-state={
                    pathname === "/cbg-oil-wholesale" ? "active" : "disable"
                  }
                >
                  CBG OIL WHOLESALE
                </Link>
              </li>
              <li class="navigation-listItem">
                <Link
                  to="/contact"
                  className="navigation-link"
                  data-state={pathname === "/contact" ? "active" : "disable"}
                >
                  Initiate A Conversation
                </Link>
              </li>
            </ul>
            <div class="nav-social _mobile-only">
              <a
                class="-color-gray globalFooter-link"
                href="mailto:info@civsav.com"
                title="Reach out to Civilized Savage"
              >
                info@cbgwholesalers.com
              </a>
              <a
                class="-color-gray globalFooter-link"
                href="https://instagram.com/civ.sav"
                title="Follow Civilized Savage on instagram"
              >
                @cbgwholesalers
              </a>
              <form
                id="header_navigation_mc_embed_signup"
                name="mc-embedded-subscribe-form"
                class="newsletter -small validate"
                novalidate=""
                onSubmit={handleSubmit}
              >
                <div id="header_navigation_mc_embed_signup_scroll">
                  <div class="mc-field-group">
                    <label
                      htmlFor="header_navigation_mce-EMAIL"
                      class="_hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      class="newsletter-input -small required email"
                      id="header_navigation_mce-EMAIL"
                      placeholder="Enter Email for Updates"
                      onChange={_handleChange}
                      value={email}
                    />
                  </div>
                  <div class="mc-field-group">
                    {error && (
                      <label htmlFor="footer_mce-EMAIL" className="_error">
                        {errorMsg}
                      </label>
                    )}
                    {success && (
                      <label htmlFor="footer_mce-EMAIL" className="_success">
                        Successfully Subscribed!
                      </label>
                    )}
                  </div>
                  <button
                    class="newsletter-submit"
                    // type="button"
                    id="header_navigation_mc-embedded-subscribe"
                    disabled={loading}
                  >
                    {loading ? (
                      <HashLoader size={20} color={"#D5B795"} />
                    ) : (
                      <>
                        <span class="_hidden">Subscribe</span>
                        <svg
                          width="16"
                          height="13"
                          viewBox="0 0 16 13"
                          fill="#BBBBBE"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6.81016 12.8243H8.89016L15.1622 6.55227L8.89016 0.280273H6.81016L12.3302 5.78427H0.410156V7.32027H12.3302L6.81016 12.8243Z"></path>
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </nav>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
